import React, { useState } from "react"

import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import { faBolt } from "@fortawesome/free-solid-svg-icons"
import useTranslations from "components/use-translations"

export default function TiktokAyirici() {
  const {
    cekilis: {
      tikTokSeoTitle,
      tikTokSeoDesc,
      tikTokCekilisAraci,
      uyari,
      uyariParagraf2,
      tikTokUyariParagraf3,
      instagramPlaceholder,
      yorumdaSuKelimelerinTamamiOlmali,
      yorumdaSuKelimelerPlaceholder,
      tekrarEtmeLimiti,
      enAzMentionAd,
      enAzHashtagAd,
      yorumlariAyristirveKullaniciAdlariniGetir,
      nasilYapilir,
      nasilYapilirAciklama,
      tikTokAdim1,
      tikTokAdim2,
      tikTokAdim3,
      tikTokAdim4,
      tikTokAdim5,
      tikTokAdim6,
      kullaniciIsimleriBuradaGorunecek,
      kaynakKoduHatali,
    },
  } = useTranslations()
  const [showAlert, setShowAlert] = useState(true)

  const [text, setText] = useState("")
  const [words, setWords] = useState("")
  const [maxSameUser, setMaxSameUser] = useState(1)
  const [minMention, setMinMention] = useState(0)
  const [minHashtag, setMinHashtag] = useState(0)

  const [result, setResult] = useState("")
  const [wrongSourceCode, setWrongSourceCode] = useState(false)

  const parseCommentsAndUsers = () => {
    let arr = []
    try {
      arr = parse()
    } catch (error) {
      console.log(error)
      setWrongSourceCode(true)
      return
    }
    arr = arr.filter(x => filterArr(x.comment))
    let sameUserCountsObj = {}
    if (maxSameUser > 0) {
      const arrCopy = [...arr]
      arrCopy.forEach(x => {
        sameUserCountsObj[x.name] = sameUserCountsObj[x.name]
          ? sameUserCountsObj[x.name] + 1
          : 1
        if (sameUserCountsObj[x.name] > maxSameUser) {
          const index = arr.indexOf(x)
          if (index > -1) {
            arr.splice(index, 1)
          }
        }
      })
    }
    setResult(arr.map(x => x.name).join("\n"))
  }
  const filterArr = comment => {
    let flag = true
    if (minMention > 0)
      flag =
        flag &&
        comment.match(/\B@\w+/g) &&
        comment.match(/\B@\w+/g).length >= minMention
    if (minHashtag > 0)
      flag =
        flag &&
        comment.match(/\B#\w+/g) &&
        comment.match(/\B#\w+/g).length >= minHashtag

    if (words) {
      let wordsArr = words.split(" ")
      flag = flag && wordsArr.every(word => new RegExp(word, "i").test(comment))
    }
    return flag
  }
  const parse = () => {
    let parser = new DOMParser()
    let arr = []
    let htmlDoc = parser
      .parseFromString(
        text.replace(new RegExp("<img(.*?)>", "g"), ""),
        "text/html"
      )
      .getElementsByClassName("comments")[0]
    Array.from(htmlDoc.getElementsByClassName("comment-item")).forEach(x => {
      const name = x
        .getElementsByClassName("user-info")[0]
        .pathname.substring(1)
      const comment = x
        .getElementsByClassName("comment-text")[0]
        .innerText.split("\n")[0]
      arr.push({ name, comment })
    })
    return arr
  }

  return (
    <>
      <LayoutCekilis headerText={tikTokCekilisAraci} type={RaffleTypes.TikTok}>
        <SEO
          title={tikTokSeoTitle}
          description={tikTokSeoDesc}
          pathname="https://www.kodvizit.com/cekilis/tiktok-cekilis-araci"
          datePublished="2017-02-08T22:39:04+00:00"
          dateModified="2020-08-05T16:53:13+00:00"
        />
        {showAlert && (
          <Alert
            variant="warning"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <p>
              <b>{uyari}:</b> {uyariParagraf2}
            </p>
            <p>{tikTokUyariParagraf3}</p>
          </Alert>
        )}
        <Row>
          <Col md={result ? "9" : "12"}>
            <textarea
              placeholder={instagramPlaceholder}
              className={`form-control  text-secondary ${
                wrongSourceCode && "is-invalid"
              } `}
              id="katilimcilar"
              name="katilimcilar"
              rows="8"
              onChange={e => {
                setWrongSourceCode(false)
                setText(e.target.value)
              }}
              value={text}
              spellCheck="false"
            />
            <div className="invalid-feedback">{kaynakKoduHatali}</div>
          </Col>
          <Col className={result ? "d-block" : "d-none"}>
            <textarea
              placeholder={kullaniciIsimleriBuradaGorunecek}
              className={`form-control mb-4 text-secondary  `}
              id="katilimcilar"
              name="katilimcilar"
              rows="8"
              value={result}
              readOnly
              spellCheck="false"
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-column ml-auto mt-4 mr-auto"
          style={{ maxWidth: 500 }}
        >
          <div className="form-group">
            <label className="col-form-label-sm  mb-0">
              {yorumdaSuKelimelerinTamamiOlmali}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={yorumdaSuKelimelerPlaceholder}
              value={words}
              onChange={e => setWords(e.target.value)}
            />
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="col-form-label-sm  mb-0">
                {tekrarEtmeLimiti}
              </label>
              <input
                type="number"
                min="0"
                className="form-control"
                value={maxSameUser}
                onChange={e => setMaxSameUser(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label-sm  mb-0">{enAzMentionAd}</label>
              <input
                type="number"
                min="0"
                className="form-control"
                value={minMention}
                onChange={e => setMinMention(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label-sm mb-0">{enAzHashtagAd}</label>
              <input
                type="number"
                min="0"
                className="form-control"
                value={minHashtag}
                onChange={e => setMinHashtag(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            onClick={parseCommentsAndUsers}
            className="btn btn-info mb-4 mt-1"
          >
            <FontAwesomeIcon icon={faBolt} className="mr-2" />
            <span>{yorumlariAyristirveKullaniciAdlariniGetir}</span>
          </button>
        </div>
        <h3 className="mt-5">{nasilYapilir}</h3>
        <p
          className="text-muted"
          dangerouslySetInnerHTML={{ __html: nasilYapilirAciklama }}
        />
        <div className="bg-light rounded p-3 d-bloc mb-5">
          <div>
            <span className="badge badge-primary badge-pill mr-2">1</span>
            <span dangerouslySetInnerHTML={{ __html: tikTokAdim1 }} />
          </div>
          <div className="mt-3">
            <span className="badge badge-primary badge-pill mr-2">2</span>
            <span dangerouslySetInnerHTML={{ __html: tikTokAdim2 }} />
          </div>
          <div className="mt-3">
            <span className="badge badge-primary badge-pill mr-2">3</span>
            <span dangerouslySetInnerHTML={{ __html: tikTokAdim3 }} />
          </div>
          <div className="mt-3">
            <span className="badge badge-primary badge-pill mr-2">4</span>
            <span dangerouslySetInnerHTML={{ __html: tikTokAdim4 }} />
          </div>
          <div className="mt-3">
            <span className="badge badge-primary badge-pill mr-2">5</span>
            <span dangerouslySetInnerHTML={{ __html: tikTokAdim5 }} />
          </div>
          <div className="mt-3">
            <span className="badge badge-primary badge-pill mr-2">6</span>
            <span dangerouslySetInnerHTML={{ __html: tikTokAdim6 }} />
          </div>
        </div>
      </LayoutCekilis>
    </>
  )
}
